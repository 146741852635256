import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    AppBar,
    IconButton,
    Toolbar,
    Hidden
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        zIndex: '1200'
    },
    toolbar: {
        paddingRight: theme.spacing(3),
        backgroundColor: theme.palette.background.blue,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between'
        }
    },
    flexGrow: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 1
        },
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    logo: {
        width: 256,
        display: 'flex',
        backgroundColor: theme.palette.background.blue,
        ...theme.mixins.toolbar
    },
    logoLink: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logoImg: {
        maxWidth: '75%'
    }
}));

function TopBar({
    onOpenNavBarMobile,
    className,
    ...rest
}) {
    const classes = useStyles();

    return (
        <>
            <AppBar
                {...rest}
                className={classes.root}
                color="primary"
            >
                <Toolbar disableGutters className={classes.toolbar}>
                    <Hidden lgUp>
                        <IconButton
                            color="inherit"
                            onClick={onOpenNavBarMobile}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <div className={classes.logo}>
                        <RouterLink to="/" className={classes.logoLink}>
                            <img
                                alt="Logo"
                                src="/images/smartdialog_logo.png"
                                className={classes.logoImg}
                            />
                        </RouterLink>
                    </div>
                    <div className={classes.flexGrow} />
                    {/* Hidden replacement for profilebutton */}
                    <div />
                </Toolbar>
            </AppBar>
        </>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
