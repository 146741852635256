import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = React.forwardRef(({ href, children, ...props }, ref) => <a {...props} href={href} ref={ref} target="_blank" rel="noopener noreferrer">{children}</a>);

ExternalLink.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
};

export default ExternalLink;