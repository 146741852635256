import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const StatusComponent = ({ children }) => {
    return (
        <Grid
            style={{ height: '100%', width: '100%' }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="h3">{children}</Typography>
        </Grid>
    );
};

StatusComponent.propTypes = {
    children: PropTypes.string.isRequired
};

export default StatusComponent;