import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import StatusComponent from 'src/components/StatusComponent';
import {
    TableBodyRenderer,
    TableCellRenderer,
    TableHeadRenderer,
    TableRenderer,
    TableRowRenderer,
    LinkRenderer,
    HeadingRenderer,
    CodeBlock
} from './MarkdownRenderers';

const useStyles = makeStyles((theme) => ({
    markDownViewerRoot: {
        padding: theme.spacing(0, 2),
        fontFamily: 'Rajdhani'
    }
}));

const MarkdownViewer = ({ url }) => {
    const classes = useStyles();
    const [rawMarkdown, setRawMarkdown] = React.useState('');
    const [error, setError] = React.useState(false);

    /* Scroll to section once on page load if hash is specified */
    const { hash } = useLocation();
    const [initialHash] = React.useState(hash);

    React.useEffect(() => {
        if (rawMarkdown && initialHash) {
            const element = document.getElementById(initialHash.replace('#', ''));
            if (element) element.scrollIntoView({ top: 0, behavior: 'smooth' });
        }
    }, [initialHash, rawMarkdown]);

    React.useEffect(() => {
        let mounted = true;

        const fetchMarkdown = async () => {
            fetch(url).then((response) => {
                if (!response.ok) {
                    if (mounted) { setError(true); }
                }
                return response.text();
            }).then((textResponse) => {
                if (mounted) { setRawMarkdown(textResponse); }
            }).catch(() => {
                if (mounted) { setError(true); }
            });
        };

        fetchMarkdown();
        return () => { mounted = false; };
    }, [url]);

    if (error) {
        return <StatusComponent>Error loading markdown</StatusComponent>;
    }

    return (
        <div className={classes.markDownViewerRoot}>
            <div>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                        code: CodeBlock,
                        h1: HeadingRenderer('h1'),
                        h2: HeadingRenderer('h2'),
                        h3: HeadingRenderer('h3'),
                        h4: HeadingRenderer('h4'),
                        h5: HeadingRenderer('h5'),
                        h6: HeadingRenderer('h6'),
                        table: TableRenderer,
                        tbody: TableBodyRenderer,
                        td: TableCellRenderer,
                        thead: TableHeadRenderer,
                        tr: TableRowRenderer,
                        th: TableCellRenderer,
                        a: LinkRenderer
                    }}
                >
                    {rawMarkdown}
                </ReactMarkdown>
            </div>
        </div>
    );
};

MarkdownViewer.propTypes = {
    url: PropTypes.string
};

export default MarkdownViewer;