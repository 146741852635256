import { Card, CardContent, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { getCharCount } from '@trt2/gsm-charset-utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        marginTop: '10px',
        margin: 5
    },
    paper: {
        padding: 20
    },
    grid: {
        marginBottom: 10
    },
    statCard: {
        textAlign: 'center'
    },
    statCardContent: {
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
});

const StatCard = ({ title, value }) => {
    const classes = useStyles();

    return (
        <Grid item xs={3} className={classes.statCard}>
            <Card raised>
                <CardContent className={classes.statCardContent}>
                    <Typography gutterBottom variant="h4">{title}</Typography>
                    <Typography variant="h4">{value}</Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};

StatCard.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired
};

const SmsLength = () => {
    const classes = useStyles();
    const [messageContent, setMessageContent] = React.useState('');

    const { charCount, msgCount, charsPerSegment, encoding } = React.useMemo(() => getCharCount(messageContent), [messageContent]);

    const handleChange = (e) => setMessageContent(e.target.value);

    return (
        <div className={classes.root}>
            <Typography variant="h2" gutterBottom>SMS Character Counter</Typography>
            <div elevation={3} className={classes.paper}>
                <Grid container spacing={3} className={classes.grid}>
                    <StatCard title="Character Count" value={charCount} />
                    <StatCard title="Message Count" value={msgCount} />
                    <StatCard title="Characters per segment" value={charsPerSegment} />
                    <StatCard title="Encoding" value={encoding} />
                </Grid>
                <Card raised>
                    <CardContent>
                        <TextField
                            variant="outlined"
                            multiline
                            fullWidth
                            placeholder="Enter message here"
                            minRows={5}
                            label="Message Content"
                            value={messageContent}
                            onChange={handleChange}
                        />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default SmsLength;