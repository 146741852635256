import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { matchPath, useLocation } from 'react-router-dom';
import navBarTheme from 'src/theme/navBarTheme';
import { Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';

import NavItem from 'src/components/NavItem';
import BottomNavBar from './BottomNavBar';

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.blue,
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    toolbar: {
        paddingRight: theme.spacing(3),
        backgroundColor: theme.palette.background.blue,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.blue
        }
    },
    mobileDrawer: {
        width: drawerWidth,
    },
    desktopDrawer: {
        width: drawerWidth,
        top: 64,
        height: 'calc(100% - 64px)'
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    drawerList: {
        width: drawerWidth
    },
    logo: {
        width: '300px',
        maxWidth: '60%'
    },
    logoLink: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logoImg: {
        maxWidth: '75%'
    },
    navigation: {
        padding: '0 8px 8px 8px'
    },
    bottomNavBarContainer: {
        width: '100%',
        display: 'inline-block'
    }
}));

function renderNavItems({
    // eslint-disable-next-line react/prop-types
    items, subheader, key, ...rest
}) {
    return (
        <List key={key}>
            {subheader && <ListSubheader disableSticky disableGutters>{subheader}</ListSubheader>}
            {/* eslint-disable-next-line react/prop-types */}
            {items.reduce(
                // eslint-disable-next-line no-use-before-define
                (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({
    acc, pathname, item, depth = 0
}) {
    if (item.items) {
        const open = matchPath(pathname, {
            path: item.route,
            exact: false
        });

        acc.push(
            <NavItem
                disabled={Boolean(item.disabled)}
                depth={depth}
                href={item.route}
                icon={item.icon}
                key={item.route}
                label={item.name}
                open={Boolean(open) && !item.disabled}
                title={item.name}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                    key: item.route
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                disabled={Boolean(item.disabled)}
                depth={depth}
                href={item.route}
                icon={item.icon}
                key={item.route}
                label={item.name}
                title={item.name}
            />
        );
    }

    return acc;
}

const NavBar = ({ navConfig = [], openMobile, onMobileClose, }) => {
    const classes = useStyles();
    const location = useLocation();
    const [lastLocation, setLastLocation] = React.useState(location.pathname);

    React.useEffect(() => {
        if (location.pathname !== lastLocation && openMobile) {
            onMobileClose();
            setLastLocation(location.pathname);
        }
    }, [location.pathname, lastLocation, openMobile, onMobileClose]);

    const drawerContent = (
        <div className={classes.root}>
            <div className={classes.drawerList}>
                <nav className={classes.navigation}>
                    {renderNavItems({
                        items: navConfig,
                        subheader: '',
                        pathname: location.pathname,
                        key: ''
                    })}
                </nav>
            </div>
        </div>
    );

    return (
        <ThemeProvider theme={navBarTheme}>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{
                        paper: classes.mobileDrawer
                    }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {drawerContent}
                    <div className={classes.bottomNavBarContainer}>
                        <BottomNavBar />
                    </div>
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{
                        paper: classes.desktopDrawer
                    }}
                    open
                    variant="persistent"
                >
                    {drawerContent}
                    <div className={classes.bottomNavBarContainer}>
                        <BottomNavBar />
                    </div>
                </Drawer>
            </Hidden>
        </ThemeProvider>
    );
};

NavBar.propTypes = {
    navConfig: PropTypes.array,
    openMobile: PropTypes.bool,
    onMobileClose: PropTypes.func
};

export default NavBar;