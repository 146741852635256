/* eslint-disable react/prop-types */
import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { HashLink } from 'react-router-hash-link';
import Typography from '@material-ui/core/Typography';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const flatten = (text, child) => {
    return typeof child === 'string'
        ? text + child
        : React.Children.toArray(child.props.children).reduce(flatten, text);
};

export const CodeBlock = ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');

    if (!inline && match) {
        return (
            <SyntaxHighlighter
                style={dark}
                language={match[1]}
                PreTag="div"
                {...props}
            >
                {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
        );
    }
    return (
        <code className={className} {...props}>
            {children}
        </code>
    );
};

export const HeadingRenderer = (variant) => ({ children }) => {
    const _children = React.Children.toArray(children);
    const text = _children.reduce(flatten, '');
    const slug = text.toLowerCase().replace(/[^a-zA-Z0-9 -]/g, '').replace(/\W/g, '-');
    return <Typography variant={variant} id={slug} style={{ marginTop: 30, marginBottom: 10 }}>{children}</Typography>;
};

export const LinkRenderer = ({ href, children }) => {
    if (href?.startsWith('#')) {
        return <HashLink smooth to={href}>{children}</HashLink>;
    }
    return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>;
};

export const TableRenderer = ({ children }) => {
    return <Table size="small">{children}</Table>;
};

export const TableHeadRenderer = ({ children }) => {
    return <TableHead>{children}</TableHead>;
};

export const TableBodyRenderer = ({ children }) => {
    return <TableBody>{children}</TableBody>;
};

export const TableRowRenderer = ({ children }) => {
    return <TableRow>{children}</TableRow>;
};

export const TableCellRenderer = ({ children }) => {
    return <TableCell>{children}</TableCell>;
};