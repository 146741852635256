import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        padding: theme.spacing(1, 2),
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%'
    },
    buttonLeaf: {
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        /* '&.depth-0': {
            fontWeight: theme.typography.fontWeightMedium
        } */
    },
    icon: {
        color: theme.palette.icon,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    expandIcon: {
        marginLeft: 'auto',
        height: 16,
        width: 16
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    },
    active: {
        color: theme.palette.white,
        /*  backgroundColor: theme.palette.secondary.main, */
        fontWeight: theme.typography.fontWeightBold,
        '& $icon': {
            color: theme.palette.white
        },
        /* '&:hover': {
            backgroundColor: theme.palette.secondary.main
        } */
    }
}));

function NavItem({
    disabled,
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    ...rest
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = {
        paddingLeft
    };

    if (children) {
        return (
            <ListItem
                {...rest}
                className={classes.item}
                disableGutters
                key={title}
            >
                <Button
                    disabled={disabled}
                    className={classes.button}
                    onClick={handleToggle}
                    style={style}
                >
                    {Icon && <Icon className={classes.icon} />}
                    {title}
                    {open ? (
                        <ExpandLessIcon
                            className={classes.expandIcon}
                            color="inherit"
                        />
                    ) :
                        (
                            <ExpandMoreIcon
                                className={classes.expandIcon}
                                color="inherit"
                            />
                        )}
                </Button>
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            {...rest}
            className={classes.itemLeaf}
            disableGutters
            key={title}
        >
            <Button
                disabled={disabled}
                activeClassName={disabled ? '' : classes.active}
                className={classes.buttonLeaf}
                component={RouterLink}
                exact={false}
                style={style}
                to={href}
                onClick={(e) => { e.currentTarget.blur(); }}
            >
                {Icon && <Icon className={classes.icon} />}
                {title}
            </Button>
        </ListItem>
    );
}

NavItem.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.any,
    label: PropTypes.any,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
    open: false
};

export default NavItem;
