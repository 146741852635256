import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TopBar from 'src/components/TopBar';
import MarkdownViewer from 'src/components/MarkdownViewer';
import theme from 'src/theme';
import { useGetOpenApiConfig } from 'src/services/openapi';
import OpenApiViewer from 'src/components/OpenApiViewer';
import NavBar from 'src/components/NavBar';
import PdfViewer from 'src/components/PdfViewer';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { CssBaseline, Grid, LinearProgress } from '@material-ui/core';
import LinkCard from 'src/components/LinkCard';
import SmsLength from 'src/views/SmsLength';
import CookiesNotification from 'src/components/CookiesNotification';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    paper: {
        padding: '20px',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    content: {
        padding: '20px',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: 1,
        maxWidth: '100%',
        overflowX: 'hidden',
        left: 0,
        top: 64,
        height: 'calc(100% - 64px - env(safe-area-inset-bottom))',
        [theme.breakpoints.up('lg')]: {
            left: 256,
            width: 'calc(100vw - 256px)'
        }
    },
    linkContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '50px 0px',
        '& > :not(:last-child)': {
            [theme.breakpoints.up('md')]: {
                marginRight: 50
            }
        }
    }
}));

const appRoutes = [
    { name: 'Utils', path: '/utils/smslength', exact: true, component: SmsLength },
    { component: () => <Redirect replace to="/" /> }
];

const appNavConfig = [
    { name: 'Utils', route: '/utils/smslength', items: [{ name: 'Sms Length', route: '/utils/smslength' }] }
];

const App = () => {
    const classes = useStyles();
    const { loading, config, routes } = useGetOpenApiConfig();
    const [openNavBarMobile, setOpenNavBarMobile] = React.useState(false);

    const navConfig = React.useMemo(() => [...config, ...appNavConfig], [config]);
    const onMobileClose = React.useCallback(() => setOpenNavBarMobile(false), []);

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {loading && <LinearProgress />}
                {!loading && (
                    <Router>
                        <CookiesNotification />
                        <GoogleAnalytics>
                            <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
                            <NavBar
                                navConfig={navConfig}
                                onMobileClose={onMobileClose}
                                openMobile={openNavBarMobile}
                            />
                            <div className={classes.content}>
                                <Switch>
                                    <Route path="/" exact>
                                        <>
                                            <Paper elevation={3} className={classes.paper}>
                                                <Typography variant="h6">Welcome to the SmartDialog Api documentation pages.</Typography>
                                                <Typography variant="subtitle1">Please select API from the menu.</Typography>
                                            </Paper>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={8} container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        <LinkCard
                                                            title="SmartDialog"
                                                            text="SmartDialog. is the next generation service platform for intelligent digital communications across different instant messaging channels."
                                                            imgSrc="/images/logos/smartdialog_logo_vertical.png"
                                                            imgAlt="SmartDialog Logo"
                                                            linkText="Sign In"
                                                            linkHref="https://app.smartdialog.ai"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <LinkCard
                                                            title="Arena Interactive"
                                                            text="Customer communications solutions to engage the customer through the different stages of the customer relationship life cycle."
                                                            imgSrc="/images/logos/Arena_logo_rgb.png"
                                                            imgAlt="Arena Interactive Logo"
                                                            linkText="Read More"
                                                            linkHref="https://www.arenainteractive.fi"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={8} container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        <LinkCard
                                                            title="SmartDialog Trial"
                                                            text="Try SmartDialog with our new Trial subscription service!"
                                                            imgSrc="/images/logos/freetrial_logo.png"
                                                            imgAlt="SmartDialog Logo"
                                                            linkText="Sign Up Now"
                                                            linkHref="https://account.smartdialog.ai/freetrial"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <LinkCard
                                                            title="SmartDialog Trial"
                                                            text="Register for a SmartDialog account via our form. Our Sales will contact you after registration."
                                                            imgSrc="/images/logos/smartdialog_logo_vertical.png"
                                                            imgAlt="SmartDialog Logo"
                                                            linkText="Sign Up Now"
                                                            linkHref="https://get.smartdialog.ai"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </Route>
                                    {routes.map(({ route, type, url }) => {
                                        switch (type) {
                                            case 'markdown':
                                                return (
                                                    <Route key={route} path={route} exact>
                                                        <MarkdownViewer url={url} />
                                                    </Route>
                                                );
                                            case 'swagger':
                                                return (
                                                    <Route key={route} path={route} exact>
                                                        <OpenApiViewer url={url} />
                                                    </Route>
                                                );
                                            case 'pdf':
                                                return (
                                                    <Route key={route} path={route} exact>
                                                        <PdfViewer url={url} />
                                                    </Route>
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                    {renderRoutes(appRoutes)}
                                </Switch>
                            </div>
                        </GoogleAnalytics>
                    </Router>
                )}
            </ThemeProvider>
        </div>
    );
};

export default App;