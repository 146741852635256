import React from 'react';

const endpoint = process.env.NODE_ENV === 'development' ? 'https://api.arena.fi/openapi/dev' : 'https://api.arena.fi/openapi/v1';

const reduceRoutes = (routes) => {
    return routes?.reduce((prev, { name, route, type, items }) => {
        if (items) {
            return [...prev, { name, route, type, url: `${endpoint}/public/definition?route=${route}` }, ...reduceRoutes(items)];
        }
        return [...prev, { name, route, type, url: `${endpoint}/public/definition?route=${route}` }];
    }, []) ?? [];
};

const useGetOpenApiConfig = () => {
    const [state, setState] = React.useState({
        loading: true,
        config: [],
        routes: []
    });

    React.useEffect(() => {
        const fetchData = () => {
            setState((state) => ({
                ...state,
                loading: true
            }));

            fetch(`${endpoint}/public/definitions`)
                .then((response) => {
                    return response.json();
                })
                .then((jsonResponse) => {
                    setState((state) => ({
                        ...state,
                        config: jsonResponse,
                        routes: reduceRoutes(jsonResponse)
                    }));
                })
                .catch(() => {
                    setState((state) => ({
                        ...state,
                        config: [],
                        routes: []
                    }));
                })
                .finally(() => {
                    setState((state) => ({
                        ...state,
                        loading: false
                    }));
                });
        };

        fetchData();
    }, []);

    return state;
};

export { useGetOpenApiConfig };