import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TermsIcon from '@material-ui/icons/SubjectRounded';
import PolicyIcon from '@material-ui/icons/PolicyRounded';

const useStyles = makeStyles((theme) => ({
    bottomNavBarContainer: {
        width: '100%',
        display: 'inline-block',
        backgroundColor: theme.palette.background.blue,
        borderTop: '1px solid #fff'
    },
    button: {
        textTransform: 'inherit'
    }
}));

function BottomNavBar({
    className,
    ...rest
}) {
    const classes = useStyles();
    const location = useLocation();

    return (
        <div
            {...rest}
        >
            <BottomNavigation
                value={location.pathname}
                showLabels
                className={classes.bottomNavBarContainer}
            >
                <BottomNavigationAction className={classes.button} label="General terms" icon={<TermsIcon />} target="_blank" component={Button} href="https://www.arenainteractive.fi/wp-content/uploads/2023/06/General-terms-Arena-Interactive-Oy-2023.pdf" />
                <BottomNavigationAction className={classes.button} label="Privacy policy" icon={<PolicyIcon />} target="_blank" component={Button} href="https://www.arenainteractive.fi/privacypolicy/" />
            </BottomNavigation>
        </div>
    );
}

BottomNavBar.propTypes = {
    className: PropTypes.string
};

export default BottomNavBar;