import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from 'src/components/ExternalLink';

const useStyles = makeStyles(() => ({
    linkImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 15
    },
    linkImage: {
        height: 100,
        width: 'auto'
    },
    cardContent: {
        padding: '0 24px'
    },
    cardActions: {
        padding: '15px'
    }
}));

const LinkCard = ({ text, title, imgSrc, imgAlt, linkText, linkHref }) => {
    const classes = useStyles();

    return (
        <Card elevation={3}>
            <CardActionArea>
                <CardMedia
                    title={title}
                    className={classes.linkImageContainer}
                >
                    <img className={classes.linkImage} src={imgSrc} alt={imgAlt} />
                </CardMedia>
                <CardContent className={classes.cardContent}>
                    <Typography variant="body1">
                        {text}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
                <Button color="primary" component={ExternalLink} href={linkHref}>
                    {linkText}
                </Button>
            </CardActions>
        </Card>
    );
};

LinkCard.propTypes = {
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
    linkHref: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired
};

export default LinkCard;