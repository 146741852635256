import { createTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const baseTheme = {
    palette,
    typography,
    overrides
};

const theme = createTheme(baseTheme);
export default theme;