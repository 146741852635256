import React from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const GoogleAnalytics = ({ children }) => {
    const [initialized, setInitialized] = React.useState(false);
    const { pathname } = useLocation();

    React.useEffect(() => {
        if (GA_MEASUREMENT_ID) {
            ReactGA.initialize(GA_MEASUREMENT_ID);
            setInitialized(true);
        }
    }, []);

    React.useEffect(() => {
        // Track current location pathname
        if (initialized && pathname) {
            ReactGA.send({ hitType: 'pageview', page: pathname });
        }
    }, [initialized, pathname]);

    return children;
};

export default GoogleAnalytics;
