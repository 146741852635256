import { colors } from '@material-ui/core';

const white = '#FFFFFF';

export default {
    primary: {
        contrastText: white,
        dark: '#104277',
        main: '#7030A0',
        light: '#165da8'
    },
    secondary: {
        contrastText: white,
        dark: '#7e0099',
        main: '#8f68d5',
        light: colors.blue.A400
    },
    tertiary: {
        main: '#8f68d5'
    },
    success: {
        main: '#00d594'
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600]
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    background: {
        default: white,
        paper: white,
        gray: '#505050',
        blue: '#254a8e'
    },
    divider: colors.grey[200],
    white,
    status: {
        delivered: {
            main: '#00d594'
        },
        pending: {
            main: '#505050'
        },
        failed: {
            main: colors.red[600]
        }
    },
    doughnutCategoryColors: [
        '#7e0099',
        '#7030A0',
        '#8f68d5',
        '#505050',
        '#254a8e',
        '#000000'
    ]
};