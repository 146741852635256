import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import PropTypes from 'prop-types';

const OpenApiViewer = ({ url }) => {
    return (
        <SwaggerUI
            docExpansion="list"
            url={url}
        />
    );
};

OpenApiViewer.propTypes = {
    url: PropTypes.string
};

export default OpenApiViewer;