import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import { Pagination } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/SaveRounded';
import PropTypes from 'prop-types';
import StatusComponent from 'src/components/StatusComponent';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
    pdfViewerPageRoot: {
        display: 'flex',
        overflowX: 'hidden',
        justifyContent: 'center',
        height: '100%'
    },
    pdfViewerWrapper: {
        display: 'flex',
        flex: 1,
        overflowX: 'hidden',
        flexDirection: 'column',
        maxWidth: 1000,
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            border: '10px solid gray',
        },
        '& .react-pdf__message': {
            height: '100%'
        }
    },
    pdfControlWrapper: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center'
    },
    loadingContainer: {
        height: '100%',
        width: '100%'
    },
    pdfDocument: {
        height: '100%'
    },
    pagination: {
        flex: 1
    },
    saveButton: {
        justifySelf: 'flex-end'
    },
    scrollDiv: {
        overflowX: 'hidden',
        overflowY: 'auto'
    }
}));

const PdfViewer = ({ url }) => {
    const classes = useStyles();
    const [numberOfPages, setNumberOfPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumberOfPages(numPages);
    };

    const paginationChanged = (e, newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    const saveClick = (e) => {
        window.open(url, '_blank');
        e.currentTarget.blur();
    };

    return (
        <div className={classes.pdfViewerPageRoot}>
            <div className={classes.pdfViewerWrapper}>
                <SizeMe
                    render={({ size }) => (
                        <div className={classes.scrollDiv}>
                            <Document
                                className={classes.pdfDocument}
                                file={url}
                                onLoadSuccess={onDocumentLoadSuccess}
                                loading={<StatusComponent>Loading document</StatusComponent>}
                                error={<StatusComponent>Error Loading document</StatusComponent>}
                            >
                                <Page
                                    width={size.width}
                                    pageNumber={pageNumber}
                                    loading=""
                                />
                            </Document>
                        </div>
                    )}
                />
                <div className={classes.pdfControlWrapper}>
                    <Pagination className={classes.pagination} count={numberOfPages} color="primary" page={pageNumber} onChange={paginationChanged} />
                    <IconButton className={classes.saveButton} aria-label="save" size="small" onClick={saveClick}>
                        <SaveIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

PdfViewer.propTypes = {
    url: PropTypes.string.isRequired
};

export default PdfViewer;